
import draggable from "vuedraggable";

import { TopicNode } from "@/models";
import { defineComponent, PropType } from "@vue/runtime-core";
import CourseTreeNode from "./CourseTreeNode.vue";
import { nodeEmits, nodeProps } from "../shared";
import { mapStores } from "pinia";
import { useMainStore } from "@/stores/mainStore";
import Btn from "@/components/ui/Btn.vue";
import VueEternalLoading from "@ts-pro/vue-eternal-loading/src/components/VueEternalLoading/VueEternalLoading.vue";
import { LoadAction } from "@ts-pro/vue-eternal-loading";
import { setErrorNotification } from "@/utils";
import Spinner from "@/components/ui/Spinner.vue";
import { courseIdMixin } from "@/mixins";
import { onChangeNodePosition } from "@/components/course_tree/shared";

export default defineComponent({
	name: "TopicNode",
	props: {
		node: {
			type: Object as PropType<TopicNode>,
			required: true,
		},
		...nodeProps,
	},
	emits: {
		...nodeEmits,
	},
	mixins: [courseIdMixin],
	async created() {
		await new Promise((resolve, reject) =>
			this.$emit("loadChildren", {
				node: this.node,
				fromFirstPage: true,
				resolveFn: resolve,
				rejectFn: reject,
			}),
		);
		this.initialFetch = false;
	},
	data() {
		return {
			initialFetch: true,
			draggingChild: false,
		};
	},
	methods: {
		async onNodeDragStart() {
			this.draggingChild = true;
		},
		async onNodeDragEnd(event: { oldIndex: number; newIndex: number }) {
			this.draggingChild = false;
			try {
				await onChangeNodePosition(
					this.courseId,
					this.children,
					event.oldIndex,
					event.newIndex,
				);
			} catch (e) {
				setErrorNotification(e);
			}
		},
		async onLoadMore({ loaded, noMore, error }: LoadAction) {
			console.log("loading children");
			try {
				const moreResults: boolean = await new Promise((resolve, reject) =>
					// pass resolve/reject to parent and wait for resolution
					this.$emit("loadChildren", {
						node: this.node,
						fromFirstPage: false,
						resolveFn: resolve,
						rejectFn: reject,
					}),
				);
				if (!moreResults) {
					noMore();
				} else {
					loaded();
				}
			} catch (e) {
				setErrorNotification(e);
				error();
			}
		},
	},
	computed: {
		...mapStores(useMainStore),
		children() {
			return this.mainStore.paginatedChildrenByNodeId[this.node.id]?.data ?? [];
		},
	},
	components: { draggable, CourseTreeNode, Btn, VueEternalLoading, Spinner },
});
